<template>
  <AuthBox :wideBg="true">
    <div class="py-16">
      <h1 class="auth-title indigo--text text--darken-4 mb-10">
        {{ $t('signUpContent.login_title') }}
      </h1>
      <form @submit.prevent="submit">
        <v-row>
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.username')" :validator="$v.form.username">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.username')" />
                <v-text-field
                  v-model="form.username"
                  v-bind="attrs"
                  outlined
                  dense
                  class="rounded-lg"
                  :placeholder="$t('form.enter_your_username')"
                  maxlength="150"
                  @input="$v.form.username.$touch()"
                  @blur="$v.form.username.$touch()"
                  @keyup.enter="submit"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.password')" :validator="$v.form.password">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.password')" />
                <v-text-field
                  v-model="form.password"
                  v-bind="attrs"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  outlined
                  dense
                  class="rounded-lg"
                  :placeholder="$t('form.input_password')"
                  @input="$v.form.password.$touch()"
                  @blur="$v.form.password.$touch()"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="submit"
                ></v-text-field>
                <div class="ml-1">
                  <router-link to="reset-password" class="no-decoration">
                    {{ $t('form.forgot_password') }}
                  </router-link>
                </div>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-row>
      </form>
      <div class="mt-10">
        <v-btn
          class="mr-1 indigo white--text no-transform"
          x-large
          :disabled="!this.$v.form.$dirty"
          @click.stop="submit"
          :loading="loading"
        >
          {{ $t('signUpContent.login_btn') }}
        </v-btn>
      </div>
      <div class="mt-10">

        <router-link :to="'/sign-up'+signUpDetail" class="no-decoration">
          {{ $t('signUpContent.signup_btn') }}
        </router-link>
        {{ $t('signUpContent.have_no_acc?') }}
      </div>
    </div>
  </AuthBox>
</template>

<script>
import AuthBox from '@/components/auth/AuthBox';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'AuthLogin',
  components: { AuthBox },
  validations: {
    form: {
      username: {
        required,
      },
      password: { required },
    },
  },
  data: () => ({
    form: {
      username: '',
      password: '',
    },
    showPassword: false,
    loading: false,
  }),
  computed:{
    signUpDetail(){
      if(this.$route.query.create_company_relationship_id!=undefined)
        return "?create_company_id="+this.$route.query.create_company_relationship_id
      return ""
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.form.$invalid) {
        return;
      } else {
        this.loading = true;
        this.$store
          .dispatch('Auth/login', this.form)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.$store
              .dispatch('Auth/getAccountInfo')
              // eslint-disable-next-line no-unused-vars
              .then(res => {
                this.$router.push(this.$route.query.redirect || { name: 'Dashboard' });
              })
              .catch(err => this.$toast.error(err.message));
          })
          .catch(err => {
            if (err.message == this.$t('form.err_login')) {
              this.$toast.error(this.$t('form.wrong_pass_user'));
            } else {
              this.$toast.error(err.message);
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-transform {
  text-transform: none;
}
.no-decoration {
  text-decoration: none;
}
</style>
